<template>
	<div>
		<global-loading-spinner />
		<!--Mini Header-->
		<header-mini-top />
		<!-- header -->
		<the-header />
		<main>
			<!-- router view slot -->
			<slot />
		</main>
		<!-- footer -->
		<the-footer />
	</div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import HeaderMiniTop from '@/components/HeaderMiniTop.vue'
import GlobalLoadingSpinner from '@/components/GlobalLoadingSpinner.vue'

export default {
	name: 'AppLayout',
	components: { TheHeader, TheFooter, HeaderMiniTop, GlobalLoadingSpinner }
}
</script>
