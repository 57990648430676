<template>
	<footer class="pt-5 footer">
		<div class="container">
			<div class="row justify-content-between footer-top pt-4">
				<div
					v-for="(links, id) in footerLinks"
					:key="id"
					class="col-md-2 py-3 text-center text-md-start"
				>
					<h6>{{ links.header }}</h6>
					<ul class="list-unstyled">
						<li v-for="(link, idx) in links.children" :key="idx" class="my-1">
							<a
								:href="link.href"
								class="text-decoration-none"
								target="_blank"
								rel="nofollow noopener noreferrer"
								>{{ link.name }}</a
							>
						</li>
					</ul>
				</div>
				<div class="col-md-4 py-3 text-center text-md-start">
					<h6>Social media links</h6>
					<div
						class="d-flex my-2 social_icon justify-content-center justify-content-md-start"
					>
						<a
							v-for="link in socialLinks"
							:key="link.url"
							:href="link.url"
							:title="link.name"
							target="_blank"
							class="text-decoration-none"
							rel="nofollow noopener noreferrer"
						>
							<i :class="link.icon" class="fa-lg me-3"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="row footer-bottom">
				<div class="text-center">
					<p class="py-4 pb-3">
						Copyright &copy; {{ new Date().getFullYear() }} All Rights Reserved
						| info@onlinetailors.co.uk
					</p>
					<p class="text-white pb-2">
						<a
							:href="`${WEBSITE_URL}/privacy-policy`"
							target="_blank"
							class="text-white text-decoration-none"
							rel="nofollow noopener noreferrer"
							>Privacy policy</a
						>
						<span class="mx-2">||</span>
						<a
							:href="`${WEBSITE_URL}/terms-and-condition`"
							target="_blank"
							class="text-white text-decoration-none"
							rel="nofollow noopener noreferrer"
							>Terms and conditions</a
						>
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { footerLinks, socialLinks } from '@/data/links';
import { WEBSITE_URL } from '@/constants';

export default {
	name: 'TheFooter',
	data() {
		return {
			footerLinks,
			socialLinks,
			WEBSITE_URL
		};
	}
};
</script>
