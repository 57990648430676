export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/service/:id',
        name: 'service',
        component: () => import('@/views/Home.vue'),
        meta: {
            auth: false,
        },
    },

    // *===============================================---*
	// *--------- AUTH -------------------------------*
	// *===============================================---*

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/TheLogin.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/views/auth/TheSignup.vue'),
        meta: {
            auth: false,
        },
    },
    // *===============================================---*
	// *--------- RESET & NEW PASSWORD -------------------------------*
	// *===============================================---*
    {
        path: '/password/reset',
        name: 'reset-password',
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/password/new',
        name: 'new-password',
        component: () => import('@/views/NewPassword.vue'),
        meta: {
            auth: false,
        },
    },
    // *===============================================---*
    // *--------- CONFIRM PAYMENT  -------------------------------*
    // *===============================================---*
    {
        path: '/confirm-payment',
        name: 'confirm-payment',
        component: () => import('@/views/ConfirmPayment.vue'),
        meta: {
            auth: true,
        },
    },

    // *===============================================---*
    // *--------- USER  -------------------------------*
    // *===============================================---*
    {
        path: '/me',
        name: 'user-account-details',
        component: () => import('@/views/user/UserAccountDetails.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/me/edit',
        name: 'edit-user-account-details',
        component: () => import('@/views/user/EditUserAccountDetails.vue'),
        meta: {
            auth: true,
        },
    },

    // *===============================================---*
    // *--------- USER ORDERS  -------------------------------*
    // *===============================================---*
    {
        path: '/me/orders',
        name: 'user-orders',
        component: () => import('@/views/user/UserOrdersList.vue'),
        meta: {
            auth: true,
        },
    },
]